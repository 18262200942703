.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: #f8f9fa;
}

.logo {
    width: 150px; /* Increase the width of the logo */
    height: auto;
    margin-bottom: 20px;
    position: absolute;
    top: 5vh;
    left: 15vh;
}

.form {
    width: 300px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    top: 50vh;
    left: 15vh;
    /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);*/
}

.form .ant-form-item {
    margin-bottom: 16px;
}

.form .ant-form-item:last-child {
    margin-bottom: 0;
}

.form .ant-input {
    border-radius: 4px;
    height: 40px; /* Increase the height of the input field */
    font-size: 16px; /* Increase the font size of the input field */
}

.form .ant-btn-primary {
    width: 50%; /* Reduce the width of the button */
    border-radius: 4px;
    height: 40px;
    font-size: 16px;
    margin-left: auto; /* Align the button to the right side */
}
.bottomimg-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 50dvh; /* Adjust this value to crop the image */
    overflow: hidden; /* Hide the overflow */
}

.bottomimg {
    width: 100%;
    height: 40%;
    position: absolute;
    bottom: -10vh;
    left: 0;

}
.welcome-text {
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: bold;
    margin-left: -18vh; /* Add this line to move the text to the left */
}
.instruction-text {
    text-align: left; /* Align the text to the left */
}
.past-rides-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
}

.past-rides-card {
    width: 80%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    padding: 20px; /* Adjust as needed */
}

.past-rides-data-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Adjust as needed */
}
.past-rides-card .MuiTypography-root {
    color: #132961;
    font-size: 16px;
    font-weight: 500;
}
.home-container{
    font-family: Roboto;
}
.date-view-subscription{
    display: flex;
    padding: 13px 10px;
    justify-content: space-around;
    align-items: center;
    align-self: stretch;
    box-shadow: 0px -1px 0px 0px #EEE inset;
}
.date-view-data-subscription{
    color: #132961;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.week-button-conatiner{
    display: flex;
    justify-content: space-between;

}
.week-button {
    min-width: 180px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    background-color: #F5F5F5;
    color: #201e1e;
    flex-grow: 1;
    margin-right: 5px;
}
@media screen and (max-width: 370px) {
    .week-button {
        min-width: 150px; /* Adjust as needed */
    }
}
@media screen and (max-width: 305px) {
    .week-button {
        min-width: 120px; /* Adjust as needed */
    }
}
@media screen and (max-width: 260px) {
    .week-button {
        min-width: 90px; /* Adjust as needed */
    }
}
.week-button:last-child {
    margin-right: 0; /* Remove the margin from the last button */
}
.week-button:first-child {
    /*margin-left: 5%; !* Remove the margin from the last button *!*/
}


.week-button.active {
    background-color: #0049AF;
    color: white;

}

.week-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0px;
    box-shadow: 0px -1px 0px 0px #EEE inset;

}
.subscription-container {
    padding: 20px; /* Adjust as needed */
    background: #F6F7F8;
}

.subscription-trip-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.checkbox-container {
    display: flex;
    justify-content: space-between;
}

/* The parent div that contains the day and the .checkbox-container */
.day-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.time-slot-day-time-timepicker{
    width:100px;
}
.weekday-label{
    width: 90px;
}
.checkbox-container2 {
    display: flex;
    justify-content: space-between;
    width: 100px;
}
.weekday-label-text{
    color: var(--Dark-Primary, #132961);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
}
.subscription-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    padding: 5% 0;
}
.subscription-button{
    background-color:#0049AF;
    display: flex;
    width: 100%;
    height: 50px;
    padding: 12px;
    justify-content: center;
    align-items: center;
}
@media screen and (min-width: 600px) {
    .subscription-button {
        width: 200px; /* Adjust as needed */
    }
}
.home-container{
    font-family: Roboto;
}

.rides-button-conatiner{
    display: flex;
    justify-content: space-between;

}
.rides-button {
    min-width: 180px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    background-color: #F5F5F5;
    color: #201e1e;
    flex-grow: 1;
    margin-right: 5px;
}
@media screen and (max-width: 370px) {
    .rides-button {
        min-width: 150px; /* Adjust as needed */
    }
}
@media screen and (max-width: 305px) {
    .rides-button {
        min-width: 120px; /* Adjust as needed */
    }
}
@media screen and (max-width: 260px) {
    .rides-button {
        min-width: 90px; /* Adjust as needed */
    }
}
.rides-button:last-child {
    margin-right: 0; /* Remove the margin from the last button */
}
.rides-button:first-child {
    /*margin-left: 5%; !* Remove the margin from the last button *!*/
}


.rides-button.active {
    background-color: #0049AF;
    color: white;
}

.rides-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0px;
    box-shadow: 0px -1px 0px 0px #EEE inset;

}

.date-view{
    display: flex;
    padding: 13px 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    box-shadow: 0px -1px 0px 0px #EEE inset;
}
.date-view-data{
    color: #132961;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}



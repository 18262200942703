.support-container{
    padding: 4%;
}
.account-details-container{
    align-items: flex-start;
    margin: 15px 0 ;
}
.account-details-title{
    color: rgba(0, 0, 0, 0.55);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.account-details-data{
    color: var(--Dark-Primary, #132961);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2%;

}
.call-card-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
}
.call-card{
    width: 100%;
    height: auto;
    padding: 20px;

}
.card-upper-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}
.card-call-number{
    color: var(--Dark-Primary, #132961);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.card-call-text{
    color: var(--Dark-Primary, #132961);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.call-card-button{

}
.feedback-card-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    margin-bottom: 15px;

}
.feedback-card{
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}
.feedback-card-text{
    color: var(--Dark-Primary, #132961);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin:0px;
}
.follow-us-text{
    color: var(--Dark-Primary, #132961);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.follow-us-icons{
    display: flex;
    margin-top: 5px;

}







.logout-button-container{
    padding: 5%;
}
.logout-button{
    width:100%;
    height: 50px;
}
.day-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: inherit;
    background-color: #F5F5F5;




    border-bottom: 2px solid rgba(47, 158, 218, 0.24);
}


.day-view-date-container {
    align-self: flex-start;
    margin-top:25px;
}
.day-view-weekday {
    text-align: center;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 5px; /* Adjust as needed */
    font-weight: bold;
    color: #1641a9;
}
.day-view-card {
    width: 80%; /* Adjust as needed */
    height: 80%; /* Adjust as needed */
    max-width: 100vw; /* Prevent the card from exceeding the screen width */
    max-height: 100vh; /* Prevent the card from exceeding the screen height */
    overflow: auto;
    margin-right: 0px;
    margin-left: 10px;
}
.day-view-date {
    background-color: #ffffff; /* Set the background color to blue */
    color: #0049AF; /* Set the text color to white */
    width: 35px; /* Adjust as needed */
    height: 35px; /* Adjust as needed */
    border-radius: 50%; /* Make it a circle */
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold; /* Make the text bold */
    font-size: 12px;
    margin-top: 5px;
}
.current-day {
    background-color: #0049AF; /* Set the background color to blue */
    color: white; /* Set the text color to white */
}
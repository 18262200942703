.trip-address-card-title-container {
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    border: 2px solid var(--Primary-Color, #0049AF);
    padding: 2px 8px;
}
.trip-address-card-title-icon {
    margin-right: 5px; /* Adjust as needed */
}
.trip-address-card-title-text {
    color: var(--Primary-Color, #0049AF);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
}
.trip-address-card-title-container.no-border {
    border: none;
    padding-left: 0;
}
.MuiChip-icon{
    order: 1;
}
.card-data-upper {
    display: flex;
    align-items: center;
}
.add-margin-upper{
    margin-right: 10px;
}
.card-data-divider {
    border-top: 1px solid #EEEEEE; /* Change the color as needed */
    width: 100%;
    margin: 10px 0; /* Adjust the margin as needed */
}
.card-data-lower {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-data-lower-data{
    margin-right: 5px;
}
.ride-time-data{
    margin: 0;
    color: var(--Dark-Primary, #132961);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}
.ride-vehicle-no{
    margin: 0;
    color: var(--Dark-Primary, #132961);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
}
.ride-trip-number-container{
    display: inline-flex;
    align-items: center;
    border-radius: 25px;
    border: 1px solid var(--Primary-Color, #0049AF);
    padding: 2px 8px;
}
.ride-trip-number{
    color: var(--Primary-Color, #0049AF);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
}


.gradient-border {
    border: 2px solid;
}
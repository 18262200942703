.trip-stepbar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 55%;
    padding: 5px 15px;
}

.trip-stepbar-point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #0049AF;
}

.trip-stepbar-line {
    width: 2px;
    flex-grow: 1;
    background-color: #0049AF;
}
.trip-text{
    color: #132961;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.trip-address-card {
    display: flex;
}
.trip-address-card-container {
    /* ... existing styles ... */
    width: 100%;
    /* ... existing styles ... */
    display: flex;
    align-items: stretch;
}

.trip-address-card-title-container {
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    border: 2px solid var(--Primary-Color, #0049AF);
    padding: 2px 8px;
}
.trip-address-card-title-icon {
    margin-right: 5px; /* Adjust as needed */
}
.trip-address-card-title-text {
    color: var(--Primary-Color, #0049AF);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
}
.trip-address-card-address{
    color: var(--weak, #999);
    font-family: Arial;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
    margin-bottom: 20px;

}
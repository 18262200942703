.home-container{
    font-family: Roboto;
}
.ride-detail-container{
    margin:16px;
}
.ride-detail-trip-container{
    display: inline-flex;
    align-items: center;
    border-radius: 25px;
    border: 1px solid var(--Primary-Color, #0049AF);
    padding: 2px 8px;
    margin:16px 0;
}
.ride-detail-trip-number{
    color: var(--Primary-Color, #0049AF);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
}
.ride-detail-location-icon-container{
    display:flex;
}

.ride-detail-trip-address-card-title-container {
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    padding: 2px 8px;
}
.ride-detail-trip-address-card-title-icon {
    margin-right: 5px; /* Adjust as needed */
}
.ride-detail-trip-address-card-title-text {
    color: var(--Primary-Color, #0049AF);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
}
.ride-detail-trip-address-card-title-container.no-border {
    border: none;
    padding-left: 0;
}
.ride-detail-card-data-direction-icon{
    padding: 10px 10px 10px 0px;

}
.ride-details-manage-button-container{
    display: flex;
    justify-content: space-between;
}
.ride-details-manage-button{
    width: 48%;

}
.ride-tracking-container{
    margin-top: 16px;
}
.ride-tracking-details{
    display:flex;
    justify-content: space-between;
}
.ride-tracking-time{
    background-color: #132961; /* Change this to your preferred color */
    padding: 10px; /* Adjust as needed */
    border-radius: 6px; /* Adjust as needed */
    display: flex;
    justify-content: center;
    color: #ffffff; /* Change this to your preferred color */
}
.track-button{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

}
.ride-details-driver-info-container{
    margin-top: 16px;
}
.driver-mobile-container{
    display: flex;
    justify-content: space-between;
}
.ride-details-driver-info-header{
    color: rgba(0, 0, 0, 0.55);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.ride-details-driver-info-content{
    color: var(--Dark-Primary, #132961);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
}
.bold{
    font-weight: bold;
}
.policy-card-container{
    margin-top: 16px;
}
.call-button{
    height: 40px;

}
.policy-card{
    display:flex;
    justify-content: space-between;
}
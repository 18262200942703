.payment-page-container{
    padding: 4%;
}
.payment-page-data-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Adjust as needed */
}
.payment-page-card .MuiTypography-root {
    color: #132961;
    font-size: 16px;
    font-weight: 500;
}
.payment-button{
    margin-top: 5%;
    align-content: center;
}
.payment-page-qr-image {
    width: 100%; /* Default: Full width for small screens */
    max-width: 300px; /* Maintain max size for small screens */
    padding: 0; /* No padding */
  }
  
  /* Medium screens (tablets, small laptops) */
  @media (min-width: 768px) {
    .payment-page-qr-image {
      max-width: 400px; /* Increase size for medium screens */
    }
  }
  
  /* Large screens (desktops, large laptops) */
  @media (min-width: 992px) {
    .payment-page-qr-image {
      max-width: 500px; /* Increase size for large screens */
    }
  }
  
  
  @media (min-width: 1200px) {
    .payment-page-qr-image {
      max-width: 600px; /* Larger size for extra-large screens */
    }
  }
  
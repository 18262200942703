.user-name {
    color: #132961;
    text-align: left;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    padding-left: 20px;
    margin-top: 10px;
}
.home-welcome-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F5F5;
}
.home-logo {
    width: 100px; /* Adjust as needed */
    height: 5%; /* Adjust as needed */
    margin-top: 10px; /* Add this line */
}
.rides-detail-text{
    color: var(--Dark-Primary, #132961);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.back-arrow-icon{
    margin-left: 20px;
}